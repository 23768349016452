<template>
  <div class="tariffs-table">
    <div class="tariffs-table__tools">
      <div class="tariffs-table__tools-start">
        <base-checkbox :model-value="checkedAll" @change="onSelectAllChanged">
          {{ $t('selectAll') }}
        </base-checkbox>
      </div>
      <div class="tariffs-table__tools-end">
        <div
          v-if="checkedList.length"
          class="tariffs-table__tools-note standart-text l-hidden-ms-down"
        >
          {{ $tc('selected', checkedList.length) }}
        </div>
        <plain-button
          v-if="isMultiprolongAvailable"
          color="primary"
          class="tariffs-table__prolong"
          @click="multiProlong(checkedListInstances)"
        >
          {{ $t('prolong.action') }}
        </plain-button>
        <plain-button
          v-if="isMultiRemoveFromBasketAvailable"
          color="del"
          class="tariffs-table__remove"
          @click="removeOrders"
        >
          {{ $t('removeOrders.action') }}
        </plain-button>
        <div
          v-else-if="
            checkedList.length && !isMultiprolongAvailable && !isMultiRemoveFromBasketAvailable
          "
          class="tariffs-table__tools-disabled standart-text note-color"
        >
          {{ $t('multiActionsDisabled') }}
        </div>
      </div>
    </div>
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        :checked="checkedList.includes(item.id)"
        @click-more="$emit('click-more', item)"
        @checked="onChecked(item.id, $event)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableRow from './TariffsTableRow';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import NewPagination from '@/components/Pagination/NewPagination';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import prolongMulti from '@/mixins/billmgr/prolongMulti';
import Vue from 'vue';
import pagination from '@/mixins/pagination';
export default {
  name: 'TariffsTableSsl',
  components: { BaseTable, TariffsTableRow, BaseCheckbox, NewPagination },
  mixins: [prolongMulti, pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'check',
          style: {
            width: '44px',
          },
        },
        {
          key: 'server',
          label: this.$t('tableHead.server'),
          style: {
            //flex: '1 1 auto',
            width: '230px',
            maxWidth: '230px',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'domain',
          label: this.$t('tableHead.domain'),
          style: {
            width: '80px',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '80px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },
        // {
        //   key: 'datacentername',
        //   label: this.$t('tableHead.datacentername'),
        //   style: {
        //     width: '134px',
        //   },
        // },
        {
          key: 'expiredate',
          label: this.$t('tableHead.expiredate'),
          style: {
            width: '84px',
          },
          sort: {
            prop: 'expiredate',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.ap'),
          key: 'autoprolong',
          style: {
            width: '44px',
          },
        },
        // {
        //   key: 'period',
        //   label: this.$t('tableHead.period'),
        //   style: {
        //     width: '134px',
        //   },
        // },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          style: {
            width: '90px',
          },
          sort: {
            prop: 'cost',
            order: 'asc',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '106px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        // {
        //   key: 'more',
        //   label: this.$t('tableHead.menu'),
        //   style: {
        //     width: '44px',
        //   },
        // },
      ],
      checkedList: [],
      checkedAll: false,
      /*stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },*/
      moduleMain: 'moduleSSL',
      sureModal: null,
      removeOrderModal: null,
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleSSL/GET_SORTED_LIST'];
    },
    checkedListInstances() {
      return this.dataset.reduce((acc, item) => {
        if (this.checkedList.includes(item.id)) acc.push(item);
        return acc;
      }, []);
    },
    sort() {
      return this.$store.state.moduleSSL.sort;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
    tools() {
      return this.$store.state.moduleSSL.tools;
    },
    isMultiprolongAvailable() {
      return (
        this.checkedList.length && this.checkedList.every(item => this.isItemProlongAvailable(item))
      );
    },
    isMultiRemoveFromBasketAvailable() {
      return (
        this.checkedList.length && this.checkedListInstances.every(item => item.status.code === 1)
      );
    },
  },
  watch: {
    checkedList: function (list) {
      if (list.length < this.dataset.length) {
        this.checkedAll = false;
      }
    },
    sort: {
      handler(val /*old*/) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onChecked(id, val) {
      const index = this.checkedList.findIndex(i => i === id);
      // if val is true and is not in list
      if (val && !~index) this.checkedList.push(id);
      // if val is false and is in list
      else if (!val && ~index) this.checkedList.splice(index, 1);
      // else do nothing
    },
    onSelectAllChanged(val) {
      this.checkedAll = val;
      if (val) {
        // this.dataset.forEach(item => {
        this.list.forEach(item => {
          if (!this.checkedList.includes(item.id)) this.checkedList.push(item.id);
        });
      } else {
        this.checkedList = [];
      }
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleSSL/SET_SORT', { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
    isItemProlongAvailable(id) {
      const inst = this.dataset.find(i => i.id === id);
      if (!inst) return false;
      return (
        inst.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(inst)
      );
    },
    async removeOrders() {
      let names = this.checkedListInstances.map(item => `${item.pricelist} #${item.id}`).join(', ');
      let msg = this.$t('removeOrders.text') + ' ' + names;
      const isSure = await this.askSure(msg);
      if (this.sureModal) this.$modals.close({ name: this.sureModal.name });
      if (isSure) {
        this.$modals.open({
          name: 'RemoveOrders',
          component: BaseLoader,
          closable: false,
          onOpen: inst => (this.removeOrderModal = inst),
          onClose: () => (this.removeOrderModal = null),
        });
        const promises = [];
        this.checkedList.forEach(id => {
          promises.push(this.$store.dispatch('moduleBasket/removeFromBasket', id));
        });
        let res = '';
        Promise.allSettled(promises)
          .then(() => (res = 'success'))
          .catch(() => (res = 'fail'))
          .finally(() => {
            Vue.set(this.removeOrderModal, 'text', this.$t(`removeOrders.${res}`));
            Vue.set(this.removeOrderModal, 'component', null);
            Vue.set(this.removeOrderModal, 'closable', true);
            Vue.set(this.removeOrderModal, 'footer', {
              centered: true,
              confirm: {
                props: { title: this.$t('removeOrders.ok') },
                on: { click: () => this.$modals.close() },
              },
            });
            this.$store.dispatch(`${this.moduleMain}/updateList`);
            this.$store.dispatch('moduleBasket/fetchBasket');
          });
      }
    },
    askSure(msg) {
      return new Promise(resolve => {
        this.$modals.open({
          name: 'AskSure',
          title: this.$t('askSure.title'),
          text: this.$t('askSure.text', { msg }),
          onOpen: inst => (this.sureModal = inst),
          onClose: () => (this.sureModal = null),
          onDismiss: () => resolve(false),
          footer: {
            confirm: {
              props: { title: this.$t('askSure.confirm') },
              on: {
                click: () => resolve(true),
              },
            },
            cancel: {
              props: { title: this.$t('askSure.cancel') },
              on: {
                click: () => resolve(false),
              },
            },
          },
        });
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "server": "Тариф",
      "expiredate": "Действует до",
      "active": "Активен",
      "id": "ID",
      "ap": "Автопродление",
      "datacentername": "Дата-центр",
      "period": "Период",
      "mem": "RAM",
      "disc": "HDD",
      "cost": "Цена/год",
      "status": "Статус",
      "domain": "Домен",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 сертификатов | Выбран 1 сертификат | Выбрано {n} сертификата | Выбрано {n} сертификатов",
    "removeOrders": {
      "action": "Удалить заказы",
      "text": "удалить заказы",
      "success": "Заказы успешно удалены",
      "fail": "Что-то пошло не так, попробуйте повторить позднее",
      "ok": "Понятно"
    },
    "askSure": {
      "title": "Подтвердите действие",
      "text": "Вы уверены, что хотите {msg}?",
      "confirm": "Да",
      "cancel": "Отмена"
    },
    "multiActionsDisabled": "Для выбранных услуг массовые действия недоступны",
    "prolong": {
      "action": "Продлить",
      "disabled": "Выбраны услуги, для которых продление недоступно",
      "title": "Продление тарифов",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифов {msg} успешно добавлено в корзину.",
      "success_order": "Продление тарифов {msg} прошло успешно. Данные скоро обновятся.",
      "error_result": "Продление тарифов {msg} не удалось.",
      "confirm": "Оплатить",
      "order": "В корзину"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center, wrap);
    margin: -0.25rem -0.25rem 1.25rem;

    &-start,
    &-end {
      margin: 0.25rem;
    }
    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
